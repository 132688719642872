<script setup lang="ts">
import type { NovaBoxLiveListWrapProps } from './NovaBoxLiveListWrap.types'
import { type OnAirLive } from '@store'
import { LiveMode } from '@sendbird'

withDefaults(defineProps<NovaBoxLiveListWrapProps>(), {
  barMode: false,
  barModeLiveListTopPosition: 0,
})

const { enterLive } = useSendbirdGate()
const sendbirdStore = useSendbirdStore()
const userStore = useUserStore()

const handleOnEnterLive = async (live: OnAirLive) => {
  await enterLive({
    mode:
      live.userSn === userStore.user?.userSn ? LiveMode.HOST : LiveMode.GUEST,
    checkPermissionType: 'sndbrdLiveSn',
    sndbrdLiveSn: live.sndbrdLiveSn,
    liveOnAirAt: 'Y',
  })
}
</script>

<template>
  <div :class="['live-list', { 'bar-mode': barMode }]" :style="{}">
    <NovaRecyclerGrid
      v-if="sendbirdStore.onAirLives.items.length"
      :items="sendbirdStore.onAirLives.items"
      :is-last="sendbirdStore.onAirLives.last"
      :is-loading="sendbirdStore.onAirLives.loading"
      :is-refresh="sendbirdStore.onAirLives.refresh"
      :key-name="'sndbrdLiveSn'"
      :responsive="{
        mobile: { columns: 1, rowGap: 12, columnGap: 12 },
        tablet: { columns: 1, rowGap: 12, columnGap: 12 },
        desktop: { columns: 1, rowGap: 12, columnGap: 12 },
      }"
      @load-more="() => fetchOnAirLives(false)"
    >
      <template #item="{ item, index }">
        <NovaBoxLiveListIem
          :on-air-live="item"
          :enter-on-air-live="handleOnEnterLive"
          :last="sendbirdStore.onAirLives.items.length - 1 === index"
        />
      </template>
    </NovaRecyclerGrid>

    <NovaBoxEmptyMessage
      v-else
      :message="$t('sendbird.enterLiveEvent.errors.2011')"
      :fill-max="barMode"
    />
  </div>
</template>

<style lang="scss" scoped>
.live-list {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
