<script setup lang="ts">
import type { NovaBoxLiveListProps } from './NovaBoxLiveList.types'
import { NovaBoxLiveListHeader } from '#components'

const props = defineProps<NovaBoxLiveListProps>()

const sendbirdStore = useSendbirdStore()
const layoutStore = useLayoutStore()
const liveListHeaderRef = ref<InstanceType<typeof NovaBoxLiveListHeader>>()
const barModeLiveListStyle = ref<Partial<CSSStyleDeclaration>>({})
const open = ref(false)
const count = computed(() => sendbirdStore.onAirLives.items.length)
const { gtEvent } = useGoogleTag()

watch(
  () => open.value,
  (cur) => {
    if (props.barMode) {
      useEvent('main:scrollLock', cur)
    }
  }
)

onBeforeMount(() => {
  if (props.barMode) {
    window.addEventListener('resize', handleOnClose)
  }
})

onBeforeUnmount(() => {
  if (props.barMode) {
    window.removeEventListener('resize', handleOnClose)
  }
})

// 리스트 닫기
const handleOnClose = () => {
  open.value = false
}

// 리스트 열기, 닫기
const handleOnToggleOpen = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '오른쪽 사이드 메뉴 버튼',
    eventLabel: 'LIVE',
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'NovaBoxLiveListHeader',
  })
  if (!open.value && !count.value) {
    return
  }

  if (!open.value && props.barMode) {
    const headerHeight = layoutStore.headerRect?.height || 0
    const listHeaderHeight =
      (liveListHeaderRef.value?.$el.getBoundingClientRect().height as number) ||
      0
    const offsetTop = headerHeight + listHeaderHeight

    barModeLiveListStyle.value = {
      position: 'fixed',
      top: `${offsetTop}px`,
      left: '0px',
      width: '100%',
      height: `calc(100% - ${offsetTop}px)`,
      zIndex: '11',
      background: '#ffffff',
    }
  } else {
    barModeLiveListStyle.value = {}
  }

  open.value = !open.value
}

// OnAir 라이브 리스트 조회
const fetchOnAirLives = async (refresh = false) => {
  await sendbirdStore.fetchOnAirLives(refresh)
}
</script>

<template>
  <ClientOnly>
    <NovaBoxBase
      v-if="!(barMode && count === 0)"
      :class="['box-live-list', { 'bar-mode': barMode }, { on: open }]"
    >
      <NovaBoxLiveListHeader
        ref="liveListHeaderRef"
        :bar-mode="barMode"
        :live-count="count"
        :open="open"
        @click="handleOnToggleOpen"
      />

      <NovaBoxLiveListWrap
        v-if="open"
        :bar-mode="barMode"
        :on-air-lives="sendbirdStore.onAirLives.items"
        :fetch-on-air-lives="fetchOnAirLives"
        :style="barModeLiveListStyle"
      />
    </NovaBoxBase>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.box-live-list {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;

  &.bar-mode {
    display: none;
    border-radius: 0 !important;

    @include mobile {
      display: flex;
    }
  }
}
</style>
