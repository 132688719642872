<script setup lang="ts">
import type { NovaBoxLiveListIemProps } from './NovaBoxLiveListIem.types'

const props = withDefaults(defineProps<NovaBoxLiveListIemProps>(), {
  last: false,
})

const { t } = useI18n()
const { gtEvent } = useGoogleTag()
const handleOnEnterLive = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '오른쪽 사이드 메뉴 LIVE',
    eventLabel: props.onAirLive.userNcnm,
    eventSlot: '',
    eventI18nAddr: props.onAirLive.userNcnm,
    eventComponent: 'Button',
  })
  props.enterOnAirLive(props.onAirLive)
}
</script>

<template>
  <div :class="['live-list-item', { last }]">
    <div class="creator-info">
      <NovaPortraitContainer
        class="portrait"
        :size="'sm'"
        :stroke="true"
        :highlight-stroke="true"
        :image-url="onAirLive.userProfileUrl"
      />

      <span class="creator-name">{{ onAirLive.userNcnm }}</span>
    </div>

    <NovaButtonText
      :label="t('sendbird.enterLiveEvent.actions.enterLive')"
      :theme="'primary-blue'"
      :size="28"
      class="button-enter-live"
      @click="handleOnEnterLive"
    />
  </div>
</template>

<style lang="scss" scoped>
.live-list-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 12px 16px;

  .creator-info {
    display: flex;
    align-items: center;
    gap: 8px;

    .portrait {
      flex-shrink: 0;
    }

    .creator-name {
      font-size: 10px;
      font-weight: 700;
      line-height: 14.48px;
      letter-spacing: -5%;
      color: $color-text-2;
      @include ellipsis(1);
    }
  }

  .button-enter-live {
    flex-shrink: 0;
  }

  &:after {
    position: absolute;
    left: 50%;
    bottom: 0;
    display: block;
    content: '';
    width: calc(100% - 16px - 16px);
    height: 1px;
    background-color: $color-bg-custom-2;
    transform: translateX(-50%);
  }

  &.last {
    &:after {
      display: none;
    }
  }
}
</style>
